import { Box, Button, Typography } from '@mui/material';
import React, { Dispatch, PropsWithChildren, useRef, useContext, useEffect, useState } from 'react';
import { Conversation, ConversationPrompt, ConversationPromptType, ConversationType } from '../../data';
import { axiosInstance } from '../../../axiosApi';
import { AxiosResponse } from 'axios';
import ConversationDetailCard, {ConversationWebSocketCard} from '../ConversationDetailCard/ConversationDetailCard';
import { Form, Formik } from 'formik';
import CustomTextField from '../CustomTextField/CustomTextField';
import { WebSocketContext } from '../../contexts/WebSocketContext';
import { AccountContext } from '../../contexts/AccountContext';
import * as Yup from 'yup';

type RenderMessageProps= {
    response: string
    index: number
};

type AsyncChatProps = {
    selectedConversation: number | undefined
    conversationTitle: string
    conversations: Conversation[]
    setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>
    setSelectedConversation: React.Dispatch<React.SetStateAction<number | undefined>>
}

const validationSchema = Yup.object().shape({
    prompt: Yup.string().min(1, "Need to have at least one character").required("This is requried")
}
)

const AsyncChat = ({selectedConversation, conversationTitle, conversations, setConversations, setSelectedConversation}: AsyncChatProps): JSX.Element => {

    
    const messageEndRef = useRef(null);
    
    const [conversationDetails, setConversationDetails] = useState<ConversationPrompt[]>([])
    const [disableInput, setDisableInput] = useState<boolean>(false);

    const [subscribe, unsubscribe] = useContext(WebSocketContext)
    const { account, setAccount } = useContext(AccountContext)
    const messageRef = useRef('')
    const conversationRef = useRef(conversationDetails)
    const [stateMessage, setStateMessage] = useState<string>('')
    const selectedConversationRef = useRef<undefined | number>(undefined)

    const handleAssistantPrompt = async ({prompt}: PromptValues): Promise<void> => {
        

        // send the prompt to be saved 
        try{
                await axiosInstance.post('/conversation_details', {
                    prompt: prompt,
                    conversation_id: selectedConversationRef.current,
                    is_user: false
                })
            
            
        }catch{
            console.log('error ')
        }
    }
    
    useEffect(() => {
        /* register a consistent channel name for identifing this chat messages */
        const channelName = `ACCOUNT_ID_${account?.email}`

        /* subscribe to channel and register callback */        
        subscribe(channelName, (message: string) => {
            /* when a message is received just add it to the UI */
            if (message === 'END_OF_THE_STREAM_ENDER_GAME_42'){
            
                conversationRef.current.pop()
            
                handleAssistantPrompt({prompt: messageRef.current})
                setConversationDetails([...conversationRef.current, new ConversationPrompt({message: `${messageRef.current}`, user_created:false})])
                messageRef.current = ''
                setStateMessage('')
                


            }else{
                messageRef.current += message
                setStateMessage(messageRef.current)

            }
            
        })

        return () => {
            /* unsubscribe from channel during cleanup */
            unsubscribe(channelName)
        }
    }, [account, subscribe, unsubscribe])
    

    async function GetConversationDetails(){
        if(selectedConversation){
            
            try{
                console.log('GetConversationDetails')
                //setPromptProcessing(true)
                selectedConversationRef.current = selectedConversation;
                const {data, }: AxiosResponse<ConversationPromptType[]> = await axiosInstance.get(`conversation_details?conversation_id=${selectedConversation}`)
                
                const tempConversations: ConversationPrompt[] = data.map((item) => new ConversationPrompt({
                    message: item.message,
                    user_created: item.user_created,
                    created_timestamp: item.created_timestamp
                }))
                conversationRef.current = tempConversations
                setConversationDetails(tempConversations)

            }finally{
                //setPromptProcessing(false)

            }
            
        }
    }

    useEffect(() => {
        GetConversationDetails();
    }, [selectedConversation])


    // Function to render each message

    const renderMessage = ({response, index}: RenderMessageProps) => (
        <div key={index}>
            <p>{response}</p>
        </div>
    );

    type PromptValues = {
        prompt: string;
    };

    const handlePromptSubmit = async ({prompt}: PromptValues): Promise<void> => {
        

        // send the prompt to be saved 
        try{
            const tempConversations: ConversationPrompt[] = [...conversationDetails, new ConversationPrompt({message: prompt, user_created:true}), new ConversationPrompt({message: '', user_created:false})]
            conversationRef.current = tempConversations
            setConversationDetails(tempConversations)
                await axiosInstance.post('/conversation_details', {
                    prompt: prompt,
                    conversation_id: selectedConversation,
                    is_user: true
                })
            
            
        }catch{
            console.log('error ')
        }
    }


    return(
        
            <div className="card" style= {{height: 'auto'}}>
                <div className='card-header'>
                    
                        <div className='bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1'>
                        <Typography variant="h6" style={{ flexGrow: 1 }} className='text-white font-weight-bold'>
                                {conversationTitle}
                        </Typography>
                        </div>
                    </div>
                <div className="card-body">
                    <Box sx={{
                                                    mb: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    height: 700,
                                                    overflow: "hidden",
                                                    overflowY: "scroll",
                                                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                                                    }}>
                    {/* {responses.map((response, index) => renderMessage({response, index}))} */}
                    {selectedConversation ?
                    conversationDetails.map((convo_detail) => convo_detail.message.length >0 ? <ConversationDetailCard message={convo_detail.message} user_created={convo_detail.user_created} key={convo_detail.id}/> : <ConversationDetailCard message={stateMessage} user_created={convo_detail.user_created} key={convo_detail.id}/>)
                    :
                    <p>Either select a previous conversation on start a new one.</p>
                    }
                    <div ref={messageEndRef} />

                    </Box>
                    

                </div>
                <div className='card-footer'>
                <Formik
                    initialValues={{
                        prompt: '',
                    }}
                    onSubmit={handlePromptSubmit}
                    validationSchema={validationSchema}
                    >
                        {(formik) => 
                                <Form>
                            <div className='row' style={{
                                position: 'sticky',
                                bottom: 0
                            }}>
                                <div className='col-10'>
                                    <CustomTextField label='Prompt' name='prompt' changeHandler={(e) => formik.setFieldValue('prompt',e.target.value)} isMultline={true}/>
                                    

                                </div>
                                <div className='col-2'>
                                    <Button
                                    type={'submit'}
                                    disabled={selectedConversation === undefined ? true : false || !formik.isValid}
                                    >Send

                                    </Button>
                                </div>
                            </div>
                        </Form>
                        }

                    </Formik>
                </div>
            </div>
        
    )
}

export default AsyncChat